<template>
  <div class="wrap">
    <img class="top" src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/mp/contact/top.png" alt="" />
    <div class="contact">
      <div class="contact__box">
        <img
          class="contact__img"
          src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/mp/contact/email.png"
          alt=""
        />
        <span class="contact__title">邮箱：</span>
        <span class="contact__text">syoungmall@syounggroup.com</span>
      </div>
      <div class="contact__box contact__box2">
        <img
          class="contact__img"
          src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/mp/contact/mobile.png"
          alt=""
        />
        <span class="contact__title">电话：</span>
        <span class="contact__text">+86 18107483991</span>
      </div>
    </div>
    <div class="copyright">Copyright ©2023长沙水羊网络科技有限公司 湘ICP备2023020024号</div>
  </div>
</template>

<script>
export default {
  name: 'contact'
};
</script>

<style lang="scss">
html {
  min-width: 100%;
}
.wrap {
  width: 100%;
  height: 100vh;
  background-image: url('https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/mp/contact/bg.png');
  background-size: 100% 100%;
  .top {
    width: 65.5%;
    margin-left: 17.2%;
    margin-top: 14%;
  }
  .contact {
    margin-top: 19.2%;
    &__box {
      width: 66.4%;
      margin-left: 16.8%;
      display: flex;
      // justify-content: center;
      align-items: center;
      font-size: 13px;
      color: #333333;
      white-space: nowrap;
    }
    &__box2 {
      margin-top: 1.4%;
    }
    &__img {
      width: 16px;
      margin-right: 3px;
    }
    &__title {
      font-weight: 600;
    }
  }
  .copyright {
    position: absolute;
    bottom: 6%;
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 12px;
    color: #7c7f85;
  }
}
</style>
